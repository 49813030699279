import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

import logo from '../images/logo.svg';
import AppIcon from './AppIcon';
import OutsideAlerter from './OutsideAlerter';
import AppModal from './AppModal';
import SwitchAccountModal from './permissions/SwitchAccountModal';
import config from '../constants/config';
import { NavBarItemDisplay, MenuItems } from '../constants/NavBarItems';
import { internal, getTablegroupAuthCookie } from '../services/UtilityService';
import { useUser } from '../hooks/UserProvider';

const AppNavbar = () => {
	const { user, hasPermission, logout, switchToOriginalUser } = useUser();
	const [openModal, setOpenModal] = useState(false);
	const [showSublinks, setShowSublinks] = useState(false);
	const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] = React.useState(false);
	const location = useLocation();

	const handleSwitch = () => {
		if (user?.original_customer) {
			switchToOriginalUser();
		} else {
			setOpenModal(false);
			setIsSwitchAccountModalOpen(true);
		}
	};

	const links_data = React.useMemo(() => MenuItems(
		user && user.id,
		user?.hasPurchasedCertification && user.hasCompletedPreWork && hasPermission('WG_CERTIFICATION.GET'),
		user?.is_wg_trainee,
		user?.show_team_application_guide && hasPermission('WG_TOOLKIT'),
		hasPermission('TTG_TOOLS'),
		hasPermission('TTG_ORDERS'),
		{
			enabled: true,
			link_name: user?.original_customer ? 'Switch to My Account' : 'Switch Account',
			onClick: handleSwitch,
		},
		user?.hasPurchasedAlumniIntensives
	), [user, hasPermission]);

	const hasActiveChild = (link) => {
		return link.sublinks.findIndex((l) => l.link_to === location.pathname) > -1;
	};

	const web_links_data = links_data?.filter(
		(l) => !l.display || [NavBarItemDisplay.All, NavBarItemDisplay.Web].includes(l.display)
	);

	const mobile_links_data = links_data?.filter(
		(l) => !l.display || [NavBarItemDisplay.All, NavBarItemDisplay.Mobile].includes(l.display)
	);

	return (
		<nav className='navbar'>
			<div className='navbar-content'>
				<div className='nav-header'>
					<Link to='/'>
						<img src={logo} alt='Working Genius' />
					</Link>
					<button className='nav-btn' onClick={() => setOpenModal(true)}>
						<AppIcon icon='align-justify' className='burger-menu' />
					</button>
				</div>
				<div className={'nav-links'}>
					{web_links_data.map((link, index) =>
						link.sublinks ? (
							<OutsideAlerter
								handleClickOutsideFunction={() => setShowSublinks(false)}
								key={index}
								customClassName={'flex items-center'}
							>
								<button
									id={link.id}
									className={`${link.class_name} ${hasActiveChild(link) ? link.active_class_name : ''
										}`}
									onClick={() => setShowSublinks(!showSublinks)}
								>
									{link.link_name}
									<AppIcon icon='down-micro' />
								</button>
								<div className={showSublinks ? 'nav-sublinks' : 'nav-sublinks hide-sublinks'}>
									{link.sublinks.map((sublink, index) => (
										<Link
											key={index}
											to={sublink.link_to}
											className={sublink.class_name}
											activeClassName={link.active_class_name}
											id={sublink.id}
											onClick={() => setOpenModal(false)}
										>
											{sublink.link_name}
										</Link>
									))}
								</div>
							</OutsideAlerter>
						) : link.logout ? (
							<a onClick={logout} className={link.class_name} key={index} id={link.id}>
								{link.link_name}
							</a>
						) : internal(link.link_to) ? (
							<Link
								key={index}
								to={link.link_to}
								className={link.class_name}
								activeClassName={link.active_class_name}
								id={link.id}
								onClick={() => setOpenModal(false)}
							>
								{link.link_name}
							</Link>
						) : (
							<a href={link.link_to} className={link.class_name} key={index} id={link.id}>
								{link.link_name}
							</a>
						)
					)}
				</div>
				<AppModal isOpen={openModal} className='nav-modal' overlayClassName='overlay'>
					<div className={'modal-header'}>
						<button onClick={() => setOpenModal(false)} className='close-btn'>
							<AppIcon icon='cancel' className='modal-cancel' />
						</button>
					</div>
					<div className={'modal-nav-links'}>
						{mobile_links_data.map((link, index) =>
							link.sublinks ? (
								link.sublinks.map((sublink, index) => (
									<Link
										key={index}
										to={sublink.link_to}
										className={'modal-nav-link'}
										id={sublink.id}
										onClick={() => setOpenModal(false)}
									>
										{sublink.link_name}
									</Link>
								))
							) : link.logout ? (
								<a onClick={logout} className={'modal-nav-link'} key={index} id={link.id}>
									{link.link_name}
								</a>
							) : link.onClick ? (
								<a onClick={link.onClick} className={'modal-nav-link'} key={index} id={link.id}>
									{link.link_name}
								</a>
							) : link.tablegroup_url ? (
								<a
									onClick={() => {
										let token = getTablegroupAuthCookie();
										window.open(`${config.ttg_url}/${link.tablegroup_url}/?token=${token}`);
									}}
									className='modal-nav-link'
									key={index}
									id={link.id}
								>
									{link.link_name}
								</a>
							) : internal(link.link_to) ? (
								<Link
									key={index}
									to={link.link_to}
									className={'modal-nav-link'}
									activeClassName={''}
									id={link.id}
									onClick={() => setOpenModal(false)}
								>
									{link.link_name}
								</Link>
							) : (
								<a
									key={index}
									href={link.link_to}
									className={'modal-nav-link'}
									id={link.id}
									onClick={() => setOpenModal(false)}
								>
									{link.link_name}
								</a>
							)
						)}
					</div>
				</AppModal>
			</div>
			<SwitchAccountModal isOpen={isSwitchAccountModalOpen} onClose={() => setIsSwitchAccountModalOpen(false)} />
		</nav>
	);
};

export default AppNavbar;
