import React from 'react';

export const AssessmentQuestions = [
	{
		title: 'What is The Six Types of Working Genius Assessment?',
		answer: 'The Working Genius Assessment is a simple and practical automated tool designed to help you identify the gifts you bring to work.',
	},
	{
		title: 'How much does it cost?',
		answer: 'The Six Types of Working Genius assessment costs $25.00 per license.',
	},
	{
		title: 'What is a license?',
		answer: 'One license equates to one assessment. In other words, you must purchase one license per participant.',
	},
	{
		title: 'What does the report identify?',
		answer: 'The Working Genius Assessment is a simple and practical online tool designed to help you identify the gifts you bring to work. The custom report provides detailed insights about your areas of Working Genius, Working Competency and Working Frustration along with an application section that will help you leverage this information in your work, on your team and in your life.',
	},
	{
		title: 'What type of scale is used?',
		answer: 'The scale for each statement includes three possible responses: <br /> <ul style="list-style: disc;margin-left: 2rem;"><li>Oh yeah, definitely</li><li>Sometimes/Somewhat</li><li>Oh no, definitely not</li></ul>',
	},
	{
		title: 'How long does it take to answer all the statements in the assessment?',
		answer: 'On average, responding to the 42 statements takes between 10 and 15 minutes.',
	},
	{
		title: 'How is this different than other personality assessments?',
		answer: 'The biggest difference is that Working Genius is easily and directly applied to work—how people work and how work gets done and, finally, what type of work brings someone joy and energy. In other words, it’s as much a productivity tool as it is a personality model. <br /><br /> For a team, Working Genius brings a framework to understand different types of work and offers insight into how people can better work together when they leverage one another’s strengths. The application of Working Genius leads to improvements in hiring, team communication, conversations around planning and execution, meetings and so much more.',
	},
	{
		title: 'Can WG be paired with the Five Dysfunctions of a Team?',
		answer: 'Absolutely, WG can be used as an effective trust-building tool on teams. As team members share their own geniuses and frustrations with their peers, for example, this process is a great demonstration in vulnerability which is the first step in overcoming the five dysfunctions. <br /><br /> Additionally, when teammates better understand their own and their coworkers’ relative areas of genius and frustration, they are immediately more capable of avoiding making unfair and inaccurate judgments about one another’s motivation. Finally, they are better poised to leverage their gifts for the benefit of the whole team.',
	},
	{
		title: 'What research has been done to validate WG?',
		answer: 'We are in the process of completing a validity and reliability study on the Working Genius Assessment. ',
	},
];

export const PurchasingWorkingGenius = [
	{
		title: 'Do you accept purchase orders?',
		answer: 'Yes, we accept purchase orders for orders over $500. Please select the appropriate radio button and enter your PO number in the field provided on the purchase page. Someone from The Table Group will notify you within 24 hours to approve your purchase.',
	},
	{
		title: 'Do you offer volume discounts?',
		answer: 'Yes. Our volume discount starts at 100 licenses and is automatically applied during the checkout process. The schedule is as follows: <div class=\'text-lg font-semibold my-2\'>Discount Schedule:</div><div class="mt-4"><table class="border-1 w-full lg:w-3/5"><thead class="border-1 text-left"><tr><th># Of Licenses</th><th>$ Per License</th><th>Discount</th></tr></thead><tbody><tr><td>1-99</td><td>$25.00</td><td>N/A</td></tr><tr><td>100-249</td><td>$22.50</td><td>10%</td></tr><tr><td>250-499</td><td>$21.25</td><td>15%</td></tr><tr><td>500 and Over</td><td>$20.00</td><td>20%</td></tr></tbody></table></div>',
	},
	{
		title: 'What information do I need before I purchase one or more assessment licenses?',
		answer: 'Before you purchase the license(s), you will need your contact information and payment information. You will create an account during the checkout process so that you can take the assessment. If you intend to send the assessment link to others, you will also need their email addresses. ',
	},
	{
		title: 'How will the email addresses I enter be used?',
		answer: 'The email addresses you provide will not be shared with any third parties and will remain only with The Table Group.',
	},
	{
		title: 'What should I do if I forgot my password?',
		answer: "First, try the ‘I don’t remember my password’ link on the login page. If that does not work, email us at <a href='mailto:support@workinggenius.com' class='font-semibold'>support@workinggenius.com</a>.",
	},
	{
		title: 'How does the process work?',
		answer: `There are a few required steps for setting up a Working Genius Assessment. <br /> <div class=\'underline my-2\'>Process Guide:</div>
        <ul style='list-style: disc; margin-left: 3rem;'>
            <li>Visit <a href='https://www.workinggenius.com/' target='_blank'>www.workinggenius.com</a></li>
            <li>Click ‘Buy Assessment’</li>
            <li>Add a license for each person whom you want to take the assessment</li>
            <li>Create your login information</li>
            <li>Complete checkout</li>
            <li>Select ‘Take Assessment Now’ or ‘Send Assessment to Others’</li>
            <li>If you’re sending the assessment to others, choose ‘Group’ or ‘Individual’</li>
            <li>Then, create an assessment link and email to either the Individual or the Group</li>
            <li>Login to your Working Genius administrator portal to view assessment results</li>
        </ul>`,
	},
	{
		title: 'How do I find my assessment if I do not have a Working Genius account?',
		answer: `<ul style='list-style: disc; margin-left: 3rem;'>
			<li>Click ‘Login’ from the homepage</li>
            <li>Click ‘Find Assessment’</li>
            <li>Enter the email used when you took the Working Genius assessment</li>
            <li>Create an account</li>
            <li>Your assessment results will be available in your console</li>
            <li>You can also share your assessment results with other Working Genius users from your console</li>
        </ul>`,
	},
	{
		title: 'Who can I contact if I am really lost?',
		answer: "Please email us at <a href='mailto:support@workinggenius.com' class='font-semibold'>support@workinggenius.com</a>, or call us at <a class='font-semibold' href='tel:+19252999700'>925-299-9700</a>.",
	},
];

export const GettingStarted = [
	{
		title: 'How do I access my assessment?',
		answer: "After purchasing your license(s), you can begin taking your assessment immediately by clicking the button on the purchase confirmation page; or from the home page within your administrator's console, you can select the blue icon that reads, “Take Assessment Now.”",
	},
	{
		title: 'How do I purchase more licenses?',
		answer: 'To purchase more licenses, simply click "Purchase More" in the top blue bar of the home page within your administrator\'s console. ',
	},
	{
		title: 'How quickly will I receive the Working Genius report?',
		answer: 'Your Working Genius assessment report will be emailed to you immediately after you finish the assessment questions, however it may take a few minutes to reach your inbox.  It will also be present in your assessment console.',
	},
];

export const GroupAndIndividual = [
	{
		title: 'Can I buy an assessment for someone?',
		answer: "You can purchase one or more licenses, and from the home page within the administrator's console, you can select the blue icon that reads, “Send Assessment to Others.” ",
	},
	{
		title: 'How do I add additional license(s) to an open group?',
		answer: `
        <ol style='list-style: disc; margin-left: 3rem;'>
            <li>Purchase the additional assessment license(s) if you do not have any remaining.</li>
            <li>Choose the group you wish to modify on the Assessments page in the console.</li>
            <li>After selecting the appropriate team, select the “Adjust Licenses” in the right corner of the page.</li>
            <li>Add the number of licenses needed (corresponds to the number of additional participants).</li>
            <li>Send an email containing the unique link to the new group/team members.</li>
        </ol>`,
	},
	{
		title: 'Can I generate a team map in my portal?',
		answer: `Short answer, ‘yes.’ Long answer, ‘you have two options.’  <br />
        <ol style='list-style: disc; margin-left: 3rem;'>
            <li>If you did not purchase assessments specifically for a group/team, you can manually enter team-members’ names onto a blank team map located in your “Resources” section. You must first log into your Working Genius portal. </li>
            <li>If you purchased and sent the assessments to a group from your portal, then you can automatically generate a Team Map listing team members’ geniuses and frustrations. The only caveat is that your team needs to be 15 or less people in order to fit into the PDF.</li>
        </ol>`,
	},
];

export const PreCertification = [
	{
		title: 'Refund Policy',
		answer: 'We will honor all refunds up to 14 days before your scheduled Certification Session, should you need to cancel. If you wish to reschedule to a new date, please reach out to us via email at <a class="font-semibold" href="mailto:certification@workinggenius.com" class="font-bold">certification@workinggenius.com</a> or call us at (925) 299-9700. We are able to accommodate re-schedules once and up until max. 7 days in advance of an open session to allow for certification pre-work requirements.',
	},
	{
		title: 'Schedule for Training',
		answer: "Our Certification Sessions are designed to be interactive and dynamic! While the start times vary depending on each training (and your location in the world), each day will be roughly 4 hours of virtual instruction and discussion. We have planned a couple of 5-10 minute breaks for you to grab a snack etc., but we want to maximize our time together so we don't have large break times built in to the sessions.",
	},
	{
		title: 'Pre-Work',
		answer: 'The videos for pre-work were all created specifically for Working Genius Certification so that you have a great foundation before we start training. The pre-work portal has all of the videos and PDF documents that we are asking you to view prior to your training. That said, completing all of the pre-work will ensure that you feel most equipped to participate each day. ',
	},
	{
		title: 'Certification Training Access',
		answer: 'Please check your email for the zoom link for your training! We will send this about a week prior to your scheduled training.',
	},
	{
		title: 'Resources',
		answer: 'We are excited about all the ways that you will be able to use Working Genius with individuals and teams. To enable you to do so, we will be providing you with a slide deck, debriefing tools, and plenty of other resources following your certification training!',
	},
	{
		title: 'Facilitators',
		answer: 'We have hand selected a group of talented Working Genius Master Facilitators to conduct our public training. They are experts in how to apply the Working Genius to have the most impact with teams and individuals. They look forward to spending two energizing days with you to equip you to do the same!',
	},
];

export const WorkshopFaq = [
	{
		title: <div class='text-md md:text-lg font-bold'>Is a Workshop Right for My Team?</div>,
		answer: `<div class="flex flex-col text-md md:text-lg text-blue-800">
					<div class="py-1">Workshops are designed for teams who want to apply the Six Types of Working Genius. </div>
					<div>Workshops are not speeches, seminars, or lunch-and-learns. They are live and in-depth facilitated experiences. They are best for teams of 4-15 that regularly work together.</div>
					<div class="pt-4">If you want to use Working Genius to increase team cohesion and productivity, a Workshop is a great next step.</div>
				</div>`,
	},
	{
		title: <div class='text-md md:text-lg font-bold'>How do Workshops Work? </div>,
		answer: `<div class="text-md md:text-lg text-blue-800">First, you’ll schedule a call with a Master Facilitator. The Master Facilitator is a hand-selected expert on using Working Genius with teams. They will get to know you, better understand your current challenges, and schedule your session. Sessions are 2-4 hours long and usually virtual. Sessions include some pre-work, including the Working Genius Assessment for each team member.</div>`,
	},
	{
		title: <div class='text-md md:text-lg font-bold'>Are Workshops Virtual or In-Person?</div>,
		answer: `<div class="text-md md:text-lg text-blue-800">It depends on what you need! Our Master Facilitators are amazing at engaging teams on zoom. They are also open to travel for in-person workshops.</div>`,
	},
	{
		title: <div class='text-md md:text-lg font-bold'>What if I can’t afford it?</div>,
		answer: `<div class="flex flex-col text-md md:text-lg text-blue-800">
					<p class="font-bold underline mb-2"> There are two great alternatives for teams: Certification and the Team Application Guide. </p>
					<div><a class="py-2" href="/client/certification/" target="_blank"><b>Working Genius Certification</b></a> is a virtual 2-day training, jam-packed with tools and content that will make you an expert at utilizing this model with teams and clients. </div>
                    <div>The <a href="/teamapplicationguide" target="_blank"><b>Working Genius Team Application Guide</b></a> is digital guide to leveraging the highest impact components of Working Genius on teams. </div>
				</div>`,
	},
];

export const StudentAssessmentQuestions = [
	{
		title: 'What age is best for the Student Assessment?',
		answer: 'The Student Assessment is designed for ages 14-20.',
	},
	{
		title: 'Where can I access the results?',
		answer: 'Students will receive their results at the end of the assessment. They will also receive an email with their full report, along with it being available inside the Working Genius portal.',
	},
	{
		title: 'How much does it cost?',
		answer: 'The Student Assessment costs $25.00 per license, and follows the same bulk discount structure as our original assessment.',
	},
	{
		title: 'Are the original Working Genius licenses and Student licenses different?',
		answer: 'While the assessments were developed for different age ranges, the licenses are interchangeable with both assessments.',
	},
	{
		title: 'Can I put Student Assessment results on the same team map as other assessment results?',
		answer: 'Yes, you will have the option of creating a team map with any assessment results in your portal.',
	},
];