import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import { getQueryParams, splitArray } from '../services/UtilityService';
import AllMembersList from '../components/assessments/teammap/AllMembersList';
import { useAssessments } from '../hooks/AssessmentProvider';
import TeammapService from '../services/TeammapService';

const Teammap = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const {
		teamMap: { label, members, endText },
	} = useAssessments();
	const { setTeamMap } = useAssessments();

	const location = useLocation();

	useEffect(() => {
		setTeamMap({});
		setError('');
		setLoading(true);
		TeammapService.getReportData(getQueryParams(location.href))
			.then((data) => {
				const allMembers = [...data.data];
				const members = splitArray(allMembers, 10);
				const label = data.label;
				const hasTiesWithFourOrMore =
					allMembers.filter((m) => {
						if (!m.ties) {
							return false;
						}
						let has = false;
						Object.keys(m.ties).forEach((key) => {
							has = has || m.ties[key].length >= 4;
						});
						return has;
					}).length > 0;
				const endText = hasTiesWithFourOrMore ? '* Represents a tie of 4 or more' : '';
				setTeamMap({
					endText,
					members,
					label,
				});
			})
			.catch((err) => setError(err))
			.finally(() => setLoading(false));
	}, []);

	const renderContent = () => {
		return loading || error ? null : (
			<>
				{members.map((memberList, idx) => (
					<AllMembersList
						members={memberList}
						label={label}
						key={idx}
						endText={idx === members.length - 1 ? endText : ''}
					/>
				))}
			</>
		);
	};

	return (
		<>
			<Helmet>
				<meta charSet='UTF-8' />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta httpEquiv='X-UA-Compatible' content='ie=edge' />
				<link rel='stylesheet' href='/assessments/report/styles/teampdf.css' />
				<title>The 6 Types of Working Genius Team Map Report</title>
			</Helmet>
			{renderContent()}
		</>
	);
};

export default Teammap;
